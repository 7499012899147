import React, { useEffect, useState } from "react";
import { Card, Checkbox, Stack, Switch } from "@mui/material";
import MDBox from "components/MDBox";
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import MDTypography from "components/MDTypography";

import { baseURl } from "BaseUrl";
import axios from "axios";
import { Image } from "react-bootstrap";

const explore = () => {
  const [isPublished, setIsPublished] = useState("un-published");

  const [mode, setMode] = useState("image");

  const [loading, setLoading] = useState(true);

  const [page, setPage] = useState(1);

  const [limit, setLimit] = useState(10);

  const [total, setTotal] = useState(0);

  const [totalPages, setTotalPages] = useState(0);

  const [posts, setPosts] = useState([]);

  const fetchExplorePosts = async () => {
    try {
      const res = await axios.get(`${baseURl}/post/admin?status=${isPublished}&model=${mode}&page=${page}&limit=${limit}`);
      const allPosts = res.data.data.docs;
      setPosts(allPosts);
      const total = res.data.data.totalDocs;
      setTotal(total);
      const totalPage = res.data.data.totalPages;
      setTotalPages(totalPage);
      setLimit(res.data.data.limit);
      setLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchExplorePosts();
  }, [limit, page, isPublished, mode]);

  const updatePostStatus = async (id, status) => {
    try {
      const res = await axios.put(`${baseURl}/post/admin/${id}/status`, {
        status: status,
      });
      fetchExplorePosts();
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Stack direction="row" gap={2} alignItems="center">
        <MDTypography variant="h6">Unpublished</MDTypography>
        <Switch
          checked={isPublished === "published"}
          onChange={() => {
            setIsPublished(isPublished === "published" ? "un-published" : "published");
          }}
        />
        <MDTypography variant="h6">Published</MDTypography>
      </Stack>
      <Stack direction="row" gap={1} alignItems="center">
        {["image", "audio", "video"].map((item) => (
          <>
            <label style={{ display: "flex", alignItems: "center", cursor: "pointer" }}>
              <Checkbox key={item} checked={mode === item} onChange={() => setMode(item)} />
              <MDTypography variant="h6">{item}</MDTypography>
            </label>
          </>
        ))}
      </Stack>

      <Card>
        <MDBox p={2}>
          <MDTypography variant="h6">Explore Posts</MDTypography>
        </MDBox>

        {loading ? (
          <MDBox p={2}>
            <MDTypography variant="h6">Loading...</MDTypography>
          </MDBox>
        ) : (
          <Stack direction="row" flexWrap="wrap" p={2}>
            {posts.map((post) => (
              <Stack direction="row" flexWrap="wrap" key={post?._id} p={2}>
                {mode === "image" ? (
                  <Image
                    src={post?.url}
                    alt={post?.title}
                    width="100%"
                    style={{
                      borderRadius: "10px",
                      objectFit: "cover",
                    }}
                  />
                ) : mode === "audio" ? (
                  <audio controls>
                    <source src={post?.url} type="audio/mpeg" />
                    Your browser does not support the audio element.
                  </audio>
                ) : (
                  <video
                    width="320"
                    height="240"
                    controls
                    style={{
                      borderRadius: "10px",
                    }}
                  >
                    <source src={post?.url} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                )}
                <Stack direction="row" alignItems="center">
                  <MDTypography variant="h6">Publish</MDTypography>
                  <Checkbox key={post?._id} checked={post?.status === "published"} onChange={() => updatePostStatus(post?._id, post?.status === "published" ? "un-published" : "published")} />
                </Stack>
                <Stack>
                  {post.metadata && (
                    <>
                      <MDTypography variant="h6">{post.metadata.prompt_ar}</MDTypography>
                      <MDTypography variant="h6">Size :{post.metadata.size}</MDTypography>
                      <MDTypography variant="h6">Like : {post.reactions.like}</MDTypography>
                    </>
                  )}
                  {post?.user && (
                    <>
                      <MDTypography variant="h6">{post?.user?.username}</MDTypography>
                      <MDTypography variant="h6">{post?.user?.email}</MDTypography>
                    </>
                  )}
                </Stack>
              </Stack>
            ))}
          </Stack>
        )}
        <MDBox p={2}>
          <Stack direction="row" gap={1}>
            <MDTypography variant="h6">Page:</MDTypography>
            {Array.from({ length: totalPages }, (_, i) => (
              <MDTypography
                key={i}
                variant="h6"
                sx={{
                  cursor: "pointer",
                  color: i + 1 === page ? "#ffffff" : "text.primary",
                  background: i + 1 === page ? "black" : "transparent",
                  borderRadius: "5px",
                  padding: "5px",
                }}
                onClick={() => setPage(i + 1)}
              >
                {i + 1}
              </MDTypography>
            ))}
          </Stack>
        </MDBox>
      </Card>
    </DashboardLayout>
  );
};

export default explore;
